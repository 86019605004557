exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bespoke-js": () => import("./../../../src/pages/bespoke.js" /* webpackChunkName: "component---src-pages-bespoke-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-collections-mdx-slug-js": () => import("./../../../src/pages/collections/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-collections-mdx-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-stockists-js": () => import("./../../../src/pages/stockists.js" /* webpackChunkName: "component---src-pages-stockists-js" */),
  "component---src-pages-whatsnew-finials-js": () => import("./../../../src/pages/whatsnew/finials.js" /* webpackChunkName: "component---src-pages-whatsnew-finials-js" */),
  "component---src-pages-whatsnew-index-js": () => import("./../../../src/pages/whatsnew/index.js" /* webpackChunkName: "component---src-pages-whatsnew-index-js" */),
  "component---src-pages-whatsnew-newbespoke-js": () => import("./../../../src/pages/whatsnew/newbespoke.js" /* webpackChunkName: "component---src-pages-whatsnew-newbespoke-js" */)
}

